.app__aboutus{
    position: relative;
}
.app__aboutus-overlay{
    position: absolute;
    inset: 0;
}
.app__aboutus-overlay img{
    width: 391px;
    height: 415px;
    z-index: 0;
}
.app__aboutus-content{
    width: 100%;
    z-index: 2;
}
.app__aboutus-content_about{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
}
.app__aboutus-content_knife{
    margin: 2rem 4rem;

}
.app__aboutus-content_knife img{
    height: 910px;
}
.app__aboutus-content_history{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}
.app__aboutus-content_history p,
.app__aboutus-content_about p{
    margin: 2rem 0;
    color: var(--color-gray);
}
@media screen and (max-width:2000px){
    .app__aboutus-content_knife img{
        height: 800px;
    }
    .app__aboutus-content_history p,
    .app__aboutus-content_about p{
      margin: 4rem 0;
     color: var(--color-gray);
}
     .app__aboutus-content{
        display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;

}

}

@media screen and (max-width:900px){
    .app__aboutus-content{
        flex-direction: column;

    }
    .app__aboutus-content_knife{
        margin: 4rem 0;
    }
    .app__aboutus-content_about{
        display: flex;
        align-items: center;
        text-align: center;
    }
    .app__aboutus-content_history{
        display: flex;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width:650px){
    .app__aboutus-overlay img{
        width: 80%;
        height: 320px;

    }
    .app__aboutus-content_about{
        justify-content: flex-end;
        align-items: center;
        text-align: center;
    }
    .app__aboutus-content_history{
        justify-content: flex-end;
        align-items: center;
        text-align: center;
    }
    .custom__button{
    margin-right: 5rem;
    }
    .app__aboutus-content_knife img{
        height: 500px;
    }
}