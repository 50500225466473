.app__header{
    background-color: var(--color-black);
}
.app__header-h1{
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
}
.app__header-img img{
    width: 80%;

}
@media screen and (max-width:1150px){
    .app__header-h1{
        font-size: 80px;
        letter-spacing: 0.02em;
    } 
}
@media screen and (max-width:990px){
    .custom__button{
        align-items: center;
    }
}
@media screen and (max-width:550px){
    .custom__button{
        display: flex;
        align-items: center;
        margin-left: 6rem;
    }
    .app__header-h1{
        font-size: 70px;
        text-align: center;
        letter-spacing: 0.02em;
    }
    .p__opensans{
        text-align: center;
    }
    .app__wrapper_info{
        text-align: center;
        align-items: center;
    }
    
    }
